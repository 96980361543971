export interface Variation {
  id: number;
  name: string;
  traffic: number;
  variables: Variable[];
}

export interface Variable {
  id: number;
  value: string;
}

export interface RuleProps {
  variations: Variation[];
  state: string;
  decision_page: string;
  id: number;
  metrics: Metric[];
  attributes: {
    key: string;
    value: string;
    matchType: string;
    name: string;
  }[];
  name: string;
  description: string;
  hypothesis: string;
  region_key: string;
  schedule?: ScheduleProps[] | null;
  brand_id: number;
  planned_start_date?: string | null;
  archived: number;
  meta?: any | null;
  type: string;
}

export interface MetaProps {
  brand: number;
  locales: string[];
  version: number;
  touchpoints: string[];
  decision_pages: {
    id: number;
    name: string;
    funnel_index: number;
  }[];
}

export interface ScheduleProps {
  id: number;
  rule_id: number;
  action_time: string;
  action: any;
  updated_at?: string;
  updated_by?: string;
}

export interface Metric {
  name: string;
  type: string;
  event: string;
  category: string;
  index: number;
  variations: Variation[];
}
export const validateRules = (
  rule: RuleProps
): Record<string, string> | null => {
  let errors: Record<string, string> | null = null;

  // TODO: simplify validation to be more generic
  if (rule.type === "ab") {
    if (
      rule.variations[1].variables.length > 0 &&
      getDuplicateVariables(rule.variations) !== null
    ) {
      errors = errors || {};
      errors.name = "Variables must differ between variations";
    }
  }
  if (getDuplicates(rule.variations, "name") !== null) {
    errors = errors || {};
    errors.name = "Variation name must be unique";
  }

  if (getHasDefaultNames(rule)) {
    errors = errors || {};
    errors.pattern =
      "Placeholder variation names, 'Variation #N' must be changed to something more descriptive";
  }

  if (getHasEmptyNames(rule)) {
    errors = errors || {};
    errors.empty =
      "Variation names must be at least 5 characters long and start with a letter or digit";
  }

  return errors;
};

export const getHasEmptyNames = (rule: RuleProps): boolean => {
  const disallowedVariationsNamePattern = /^(\s+.*|\S{0,4})$/;
  const res = rule.variations.some((variation: Variation) => {
    return disallowedVariationsNamePattern.test(variation.name);
  });

  return res;
};

export const getHasDefaultNames = (rule: RuleProps): boolean => {
  const disallowedVariationsNamePattern = /Variation #\d/i;
  const res = rule.variations.some((variation: Variation) => {
    return disallowedVariationsNamePattern.test(variation.name);
  });

  return res;
};

export const getDuplicates = <T>(arr: T[], key: keyof T): T[] | null => {
  const map: Record<string, boolean> = {};
  const duplicates: T[] = [];

  if (!Array.isArray(arr)) {
    return null;
  }

  arr.forEach((item) => {
    const keyValue = item[key] as unknown as string;
    if (map[keyValue]) {
      duplicates.push(item);
    } else {
      map[keyValue] = true;
    }
  });

  return duplicates.length === 0 ? null : duplicates;
};

export const getDuplicateVariables = (arr: Variation[]): Variation[] | null => {
  const map: Record<string, boolean> = {};
  const duplicates: Variation[] = [];

  if (!Array.isArray(arr)) {
    return null;
  }

  arr.forEach((item) => {
    if (item.name !== "Control") {
      const variablesString = JSON.stringify(item.variables);
      if (map[variablesString]) {
        duplicates.push(item);
      } else {
        map[variablesString] = true;
      }
    }
  });

  return duplicates.length === 0 ? null : duplicates;
};
