import { FetchTimeseries } from "./FetchData";
import { ApiResponse } from "../types/Types";
import timelineMockdataV2 from "./timelineMockdataV2.json";
import timelineMockdataV2Mab from "./timelineMabMockdataV2.json";

export const fetchTimeseries = async (props: {
  mockEnabled: boolean;
  experimentId: number;
  metricIndex: number;
  searchParams: URLSearchParams;
  variations: Record<string, string>;
  getVariationName: (variationId: number) => string;
  version: string;
  type: string;
  experimentType: string;
}): Promise<{
  result: TimeseriesEntry[];
  variations: Record<string, string>;
} | null> => {
  const {
    mockEnabled,
    experimentId,
    metricIndex,
    searchParams,
    version,
    experimentType,
  } = props;

  if (mockEnabled) {
    let variations: Record<string, string> = {};
    Object.keys(timelineMockdataV2.metric_value[0]).forEach((item: string) => {
      if (item !== "time_stamp") {
        const index = parseInt(item);
        variations[index] = props.getVariationName(index);
      }
    });
    const newVariations: Record<string, string> = {};
    Object.keys(variations).forEach((variation) => {
      newVariations[variation] = props.getVariationName(Number(variation));
    });
    if (experimentType === "mab") {
      return Promise.resolve({
        result: transformTimeseriesMab(
          timelineMockdataV2Mab,
          newVariations,
          props.type
        ),
        variations: newVariations,
      });
    } else {
      return Promise.resolve({
        result: transformTimeseriesAB(
          timelineMockdataV2,
          variations,
          props.type
        ),
        variations: variations,
      });
    }
  } else {
    return new Promise((resolve, reject) => {
      let variations: Record<string, string> = {};
      try {
        FetchTimeseries(
          version,
          experimentId,
          metricIndex,
          searchParams.get("s"),
          (result: ApiResponse) => {
            if (result.status !== 200) {
              reject(result.response);
              return;
            }

            if (result?.response?.metric_value.length < 1) {
              reject("No data found");
              return;
            }

            Object.keys(result?.response?.metric_value[0]).forEach(
              (item: string, index: number) => {
                if (item !== "time_stamp") {
                  variations[index] = item;
                }
              }
            );
            if (experimentType === "mab") {
              resolve({
                result: transformTimeseriesMab(
                  result.response,
                  variations,
                  props.type
                ),
                variations: variations,
              });
            } else {
              resolve({
                result: transformTimeseriesAB(
                  result.response,
                  variations,
                  props.type
                ),
                variations: variations,
              });
            }
          }
        );
      } catch (error) {
        reject(error);
      }
    });
  }
};

export const transformTimeseriesAB = (
  timeseries: TimeseriesAB,
  variations: Record<string, string>,
  type: string
): TimeseriesEntry[] => {
  return timeseries.metric_value.map((_, index) => {
    const timestamp = new Date(
      timeseries.metric_value[index].time_stamp as string
    ).getTime();

    const entry: TimeseriesEntry = {
      date: timestamp,
    };

    Object.values(variations).forEach((variation) => {
      let meanValue = timeseries.metric_value[index][variation] as
        | number
        | undefined;
      if (type === "prop") {
        meanValue = meanValue ? meanValue * 100 : undefined;
      }

      entry[variation] = {
        mean_value:
          meanValue !== undefined ? parseFloat(meanValue.toFixed(2)) : null,
        pct_change: timeseries.improvement[index][variation] as number | null,
        num_diff: timeseries.converted_visitors[index][variation] as
          | number
          | null,
        exposure_time: null,
        total_visitors: timeseries.total_visitors
          ? (timeseries.total_visitors[index][variation] as number | null)
          : null,
        bandit_avg_value: null,
      };
    });

    return entry;
  });
};

export const transformTimeseriesMab = (
  timeseries: TimeseriesMab,
  variations: Record<string, string>,
  type: string
): TimeseriesEntry[] => {
  return timeseries.metric_value.map((_, index) => {
    const timestamp = new Date(
      timeseries.metric_value[index].time_stamp as string
    ).getTime();

    const entry: TimeseriesEntry = {
      date: timestamp,
    };

    Object.keys(variations).forEach((variation) => {
      let variationName = variations[variation];

      let meanValue = timeseries.metric_value[index][variationName] as
        | number
        | undefined;
      if (type === "prop") {
        meanValue = meanValue ? meanValue * 100 : undefined;
      }

      entry[variationName] = {
        mean_value:
          meanValue !== undefined ? parseFloat(meanValue.toFixed(2)) : null,
        pct_change: timeseries?.uplift
          ? (timeseries.uplift[index][variationName] as number | null)
          : null,
        num_diff: timeseries.converted_visitors[index][variationName] as
          | number
          | null,
        exposure_time: timeseries.exposure_time
          ? (timeseries.exposure_time[index][variationName] as number | null)
          : null,
        total_visitors: timeseries.total_visitors
          ? (timeseries.total_visitors[index][variationName] as number | null)
          : null,
        bandit_avg_value: timeseries.bandit_avg_value
          ? (timeseries.bandit_avg_value[index]["total_value"] as number | null)
          : null,
      };
    });

    return entry;
  });
};
interface TimeseriesAB {
  experimentId: string;
  metricIndex: string;
  metric_value: { [key: string]: number | string }[];
  improvement: { [key: string]: number | string }[];
  converted_visitors: { [key: string]: number | string }[];
  total_visitors: { [key: string]: number | string }[];
  exposure_time?: { [key: string]: number | string }[];
}
export interface TimeseriesMab {
  experimentId: string;
  metricIndex: string;
  metric_value: { [key: string]: number | string }[];
  bandit_avg_value: any[];
  converted_visitors: { [key: string]: number | string }[];
  total_visitors: { [key: string]: string | number }[];
  uplift: { [key: string]: number | string }[];
  exposure_time?: { [key: string]: number | string }[];
}

export interface TimeseriesEntry {
  date: number;
  [variationName: string]:
    | number
    | {
        mean_value: number | null;
        pct_change: number | null;
        num_diff: number | null;
        exposure_time: number | null;
        total_visitors: number | null;
        bandit_avg_value: number | null;
      };
}
