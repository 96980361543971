import React from "react";
import { Select, MenuItem, SelectChangeEvent } from "@mui/material";

interface ApiVersionSelectProps {
  showChartNew: boolean;
  onShowChartNew: (value: boolean) => void;
}

export const ApiVersionSelect: React.FC<ApiVersionSelectProps> = ({
  showChartNew,
  onShowChartNew,
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    onShowChartNew(event.target.value === "new");
  };

  return (
    <Select
      labelId="api-version-label"
      value={showChartNew ? "new" : "old"}
      onChange={handleChange}
      label="API Version"
    >
      <MenuItem value="old">Api v1</MenuItem>
      <MenuItem value="new">Api v2</MenuItem>
    </Select>
  );
};

export default ApiVersionSelect;
