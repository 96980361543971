import { useState } from "react";

import moment from "moment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useConfig } from "../context";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Typography, Grid, Box } from "@mui/material";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { TimeseriesChartTooltip } from "./TimeseriesChartTooltip";
import { TimeseriesEntry } from "../helpers/metricItemtimeSeries";

interface ChartNewAPIProps {
  type: "means" | "count" | "items";
  event: string;
  category?: "monitoring";
  getVariationName: (index: number) => string;
  getVariationColor: (index: number) => string;
  name: string;
  experimentType: string;
  apiVersionSelect?: React.JSX.Element;
  defaultMetric?: string;
  timeseries: TimeseriesEntry[];
  variations: Record<string, string>;
  isLoading: boolean;
  onSelectedMetricChange: ((value: string) => void) | undefined;
}

export const ChartNewAPI = (props: ChartNewAPIProps) => {
  const {
    type,
    event,
    category,
    getVariationName,
    experimentType,
    getVariationColor,
    apiVersionSelect,
    defaultMetric,
    timeseries,
    variations,
    isLoading,
    onSelectedMetricChange,
  } = props;

  const { config } = useConfig();
  const [currentMetricValue, setCurrentSelectedMetricValue] =
    useState(defaultMetric);

  const getConversionLabel = (type: string) => {
    switch (type) {
      case "means":
        return "Value per visitor";
      case "count":
        return "Conversions per visitor";
      case "items":
        return "Items per visitor";
      default:
        return "Conversion Rate";
    }
  };

  const metricValues = [
    { value: "mean_value", label: getConversionLabel(type) },
    { value: "pct_change", label: "Improvement" },
    { value: "num_diff", label: "Total conversions" },
    { value: "total_visitors", label: "Total visitors" },
  ];

  experimentType === "mab" &&
    metricValues.push(
      { value: "bandit_avg_value", label: "Bandit avg value" },
      { value: "exposure_time", label: "Exposure time" }
    );

  const [currentLabel, setCurrentLabel] = useState(getConversionLabel(type));

  const changeMetricHandler = (event: SelectChangeEvent) => {
    onSelectedMetricChange &&
      onSelectedMetricChange(event.target.value as string);

    setCurrentSelectedMetricValue(event.target.value as string);

    setCurrentLabel(
      metricValues?.find((i) => i.value === event.target.value)?.label || ""
    );
  };

  const getYlabel = () => {
    const suffix = event === "purchase" && type === "means" ? " (SEK)" : "";
    return currentLabel + suffix;
  };

  const hasNonZeroTimeline = (
    variationId: string,
    metricKey: string | undefined
  ) => {
    const hasValues = timeseries.some((entry) => {
      if (!entry || !metricKey) return false;

      const variationData = entry[variationId];
      if (!variationData) return false;

      const value = variationData[metricKey as keyof typeof variationData];

      return value !== undefined && value !== null && value !== 0;
    });

    return hasValues;
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          marginTop: 10,
          display: "flex",
          color: "#ccc",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="overline">Loading...</Typography>
      </Box>
    );
  } else if (!isLoading && timeseries.length === 0) {
    return (
      <Box
        sx={{
          marginTop: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <DoDisturbIcon color="disabled" sx={{ fontSize: 140 }} />
        <Typography variant="overline">No data available</Typography>
      </Box>
    );
  } else {
    const fullDates: number[] = [];
    let expLength = 0;

    const startDate = moment(timeseries[0].date);
    const endDate = moment(timeseries[timeseries.length - 1].date);

    expLength = Math.round(moment.duration(endDate.diff(startDate)).asDays());

    timeseries.forEach((i, index) => {
      let interval;

      switch (true) {
        case expLength > 12:
          interval = 1;
          break;
        case expLength > 5:
          interval = 3;
          break;
        default:
          interval = 5;
          break;
      }
      const date = i.date;

      if (index % interval === 0) {
        fullDates.push(date);
      }
    });

    const xTickFormatter = (unixTime: number) => {
      const roundedTime =
        Math.round(unixTime / 1000 / 60 / 30) * 30 * 60 * 1000;
      const formattedTime = moment(roundedTime).format("HH:mm");
      const formattedDate = moment(roundedTime).format("MMM DD");
      if (fullDates.includes(unixTime)) {
        return formattedDate;
      } else {
        return formattedTime;
      }
    };

    const yTickFormatter = (value: number) => {
      const suffix: string = /Improvement|Conversion Rate/.test(currentLabel)
        ? "%"
        : "";

      return currentLabel === "Total conversions" && value % 1 !== 0
        ? ""
        : value + suffix;
    };

    return (
      <>
        <Grid container justifyContent="flex-end" spacing={4}>
          {config.user.level >= 100 && apiVersionSelect && (
            <Grid item xs="auto">
              <FormControl
                sx={{ minWidth: 220 }}
                style={{
                  zIndex: "1000",
                  marginTop: "25px",
                }}
                variant="outlined"
                size="small"
              >
                <InputLabel id="api-version-label">
                  Choose api version
                </InputLabel>
                {apiVersionSelect}
              </FormControl>
            </Grid>
          )}
          <Grid item xs="auto">
            <FormControl
              sx={{ minWidth: 220 }}
              style={{
                zIndex: "1000",
                marginTop: "25px",
              }}
              variant="outlined"
              size="small"
            >
              <InputLabel id="metric-label">Metric</InputLabel>
              <Select
                labelId="metric-label"
                value={currentMetricValue}
                onChange={(event: SelectChangeEvent) => {
                  changeMetricHandler(event);
                }}
                label="Metric"
              >
                {metricValues
                  .filter(
                    (metricValue: { value: string; label: string }) =>
                      category !== "monitoring" ||
                      metricValue.value !== "pct_change"
                  )
                  .map((metricValue) => (
                    <MenuItem key={metricValue.value} value={metricValue.value}>
                      {metricValue.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            style={{
              marginTop: "20px",
            }}
            width={730}
            height={250}
            data={timeseries}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid />
            <XAxis
              angle={-45}
              textAnchor="end"
              interval="preserveStartEnd"
              fontSize={10}
              tickSize={1}
              dataKey="date"
              tickFormatter={xTickFormatter}
            />
            <YAxis
              tickFormatter={yTickFormatter}
              type="number"
              domain={["auto", "auto"]}
              label={{
                style: { textAnchor: "middle" },
                dx: -10,
                value: getYlabel(),
                angle: -90,
                position: "insideLeft",
              }}
            />
            <Tooltip content={<TimeseriesChartTooltip />} />
            <Legend verticalAlign="bottom" />
            {Object.keys(variations).map((_: string, index: number) => {
              let variationName = getVariationName(Number(variations[index]));
              const variationId = variations[index];

              if (!hasNonZeroTimeline(variationId, currentMetricValue)) {
                return null;
              }

              return (
                <Line
                  dot={false}
                  strokeWidth={1}
                  key={`${variationId}`}
                  dataKey={variationId + `[${currentMetricValue}]`}
                  stroke={`${getVariationColor(parseInt(variationId))}`}
                  name={`${variationName}`}
                  isAnimationActive={false}
                  type="monotone"
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      </>
    );
  }
};
